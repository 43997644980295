import BrowserApiService from '../BrowserApi';
import SaleforceService  from '../../Services/SaleforceService';

const LogTitle = 'CallNotificationService';

const NotificationParams = {
  /**
   * Whether to show the incoming call notification
   */
  shouldShow: false,
};

class CallNotificationService {
  registerPageVisibilityListener = () => {
    BrowserApiService.Visibility.registerListener(this.handlePageVisibilityChange);
  }

  handlePageVisibilityChange = () => {
    const pageBecameVisible = !document[(BrowserApiService.Visibility.visibilityParams.hidden as keyof Document)];
    if (pageBecameVisible) this.handlePageGainedVisibility();
    else this.handlePageLostVisibility();
  }

  handlePageGainedVisibility = () => {
    NotificationParams.shouldShow = false;
  }

  handlePageLostVisibility = () => {
    NotificationParams.shouldShow = true;
  }

  showIncomingCallNotification = (callDetails: any, caller: any, displayName: any) => {
    if (!NotificationParams.shouldShow) {
      return;
    }

    SaleforceService.publishObject({
      type: 'DESKTOP_NOTIFICATION', 
      title: 'Incoming Call', 
      callDetails: callDetails,
      caller: caller,
      displayName: displayName,
      refId: callDetails?.callRecordId,
      status: true
    }, "rocketphone__NOTIFICATIONMC__c");
  }

  hideIncomingCallNotification = (callRecordId: string) => {
    SaleforceService.publishObject({
      type: 'DESKTOP_NOTIFICATION', 
      refId: callRecordId,
      status: false
    }, "rocketphone__NOTIFICATIONMC__c");
  }

  showMissedCallNotification = (callDetails: any, callee: string) => {
    if (!NotificationParams.shouldShow) {
      return;
    }

    SaleforceService.publishObject({
      type: 'DESKTOP_NOTIFICATION', 
      title: 'Missed Call', 
      callDetails: callDetails,
      callee,
      status: true
    }, "rocketphone__NOTIFICATIONMC__c");
  }

  showCoachingTipNotification = (callee: string) => {
    if (!NotificationParams.shouldShow) {
      return;
    }

    SaleforceService.publishObject({
      type: 'DESKTOP_NOTIFICATION', 
      title: 'Coaching Tip', 
      callee,
      status: true
    }, "rocketphone__NOTIFICATIONMC__c");
  }
}

export default new CallNotificationService();