import React, { forwardRef, useCallback, useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faPhoneOffice, faUsers } from '@fortawesome/pro-solid-svg-icons';

import CallConstants from '../../constants/Call';
import { CallBarState } from '../../constants/CallBarState';
import { CallState as NavigationDiallerCallState } from '../../Enums/Call.types';
import ContactService from '../../Services/ContactService';
import SaleforceService from '../../Services/SaleforceService';
import CallForwardService from '../../Services/Call/CallForward';
import PhoneNumberService from '../../Services/PhoneNumberService';
import TwilioVoiceService from '../../Services/TwilioVoiceService';
import OutboundCallService from '../../Services/Call/OutboundCall';
import CommunicationService from '../../Services/CommunicationService';
import CallAgentSearchService from '../../Services/Call/CallAgentSearch';
import { setUserOutboundCallNumberState } from '../../slices/auth';
import { callHold, callResume } from '../../slices/callState';
import { setMatchingContacts, cleanupContactSearchByPhone } from '../../slices/contactSearchByPhone';
import { setCallRecordingStatusData, setCallRecordingConsentData, setCallTimer } from '../../slices/callCentre';
import NavigationDialler from '../NavigationDialler';
import Typography, { TypographyVariant } from '../Typography';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { ContactPersonStatus } from '../CallForwardCard/private/ContactRow';

import { NavigationDiallerContainerProps } from './NavigationDiallerContainer.types';
import NavigationDiallerContainerContainerThemer from './NavigationDiallerContainer.theme';

const OptionType = { team: 1, user: 2 };

const renderMenuItemLabel = (label: any, icon: any, secondaryLabel: any) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <FontAwesomeIcon icon={icon as IconProp} style={{ fontSize: '16px', color: '#ffffff', minWidth: '20px' }} />
    <div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'column' }}>
      <Typography.P1 style={{ maxWidth: '18rem !important', textAlign: 'left' }} variant={TypographyVariant.light} noWrap>{secondaryLabel}</Typography.P1>
      <Typography.P1 style={{ maxWidth: '18rem !important', textAlign: 'left' }} variant={TypographyVariant.light}>{label}</Typography.P1>
    </div>
  </div>
);

const NavigationDiallerContainerComponent = forwardRef<HTMLElement, NavigationDiallerContainerProps>((props, ref) => {
  const {
    id,
    authData, settingsData,
    onCallHangup,
    hideTeams, includeUnavailableUsers, selectedSfContact,
    onNavigationDiallerUnmount, onBackSpaceClicked,
  } = props;
  
  const [callState, setCallState] = useState(NavigationDiallerCallState.dialing);
  const [selectedContact, setSelectedContact] = useState(selectedSfContact);
  const [number, setNumber] = useState(selectedSfContact?.dialNumber || selectedSfContact?.mobilePhone || selectedSfContact?.phone || '');
  const [dialFromNumber, setDialFromNumber] = useState('');
  const [dialpadVisible, setDialpadVisible] = useState(true);
  const [backSpaceVisible, setBackSpaceVisible] = useState(true);
  const [callTimeInSeconds, setCallTimeInSeconds] = useState(0);
  const [isMicMute, setIsMicMute] = useState(false);
  const [showCallForwardCard, setShowCallForwardCard] = useState(false);
  const [selectedOptionType, setSelectedOptionType] = useState(null);
  const [selectedOption, setSelectedOption] = useState<any | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [availableTeams, setAvailableTeams] = useState<any>([]);
  const [unavailableUsers, setUnavailableUsers] = useState<any>([]);
  const [allData, setAllData] = useState([]);
  const [forwardInProgress, setForwardInProgress] = useState(false);
  const [dialpadOpen, setDialpadOpen] = useState(false);
  const [dialpadNumber, setDialpadNumber] = useState('');
  const [intervalId, setIntervalId] = useState<any>(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [outgoingNumberMenuOpen, setOutgoingNumberMenuOpen] = useState(false);

  const classes = NavigationDiallerContainerContainerThemer.useStyles();
  const dispatch = useAppDispatch();
  const prevPropsRef = useRef(null);

  const contactSearchByPhoneData = useAppSelector((state: any) => state.contactSearchByPhone.data);
  const callBarStateData = useAppSelector((state: any) => state.callCentre.callBarState);
  const currentActiveCallRecordId = useAppSelector((state: any) => state.callCentre.currentActiveCallRecordId);
  const callDetailsMap = useAppSelector((state: any) => state.callCentre.callDetailsMap);
  const recordingStatusMap = useAppSelector((state: any) => state.callCentre.callRecordingStatusMap);
  const recordingConsentMap = useAppSelector((state: any) => state.callCentre.callRecordingConsentMap);
  const callsOnHoldData = useAppSelector((state: any) => state.callState.callsOnHold);
  const currentCallsOnHoldData = callsOnHoldData?.[currentActiveCallRecordId];
  const callDetails = callDetailsMap?.[currentActiveCallRecordId];
  const recordingStatus = recordingStatusMap?.[currentActiveCallRecordId];
  const recordingConsent = recordingConsentMap?.[currentActiveCallRecordId];
  let contactSearchByPhoneDataCount = selectedContact ? (contactSearchByPhoneData.length || 0) : 0;

  const resolveSearchResultsReceived = useCallback(() => {
    if (Object.keys(contactSearchByPhoneData).length > 0) {
      let contact = contactSearchByPhoneData?.[0];
      const remoteObject = contactSearchByPhoneData?.find((data: any) => data.remoteId === selectedContact?.remoteId);

      if (!remoteObject) {
        setSelectedContact(selectedContact || contact);
      } else {
        setSelectedContact(remoteObject);
      }
    }
  }, [contactSearchByPhoneData, selectedContact]);

  const handleOnCallTimerInterval = useCallback(() => {
      setCallTimeInSeconds((prevCount) => {
        dispatch(setCallTimer({ callDuration: prevCount + 1 }));

        return prevCount + 1;
      });
    }, [dispatch]);

    // Resolve the Call state according to global call bar state
  const resolveCallState = useCallback(() => {
    if (callBarStateData === CallBarState.CALLING) {
      setCallState(NavigationDiallerCallState.connecting);
      setDialpadVisible(false);
      setBackSpaceVisible(false);
    } else if (callBarStateData !== prevPropsRef.current) {
      if (callBarStateData === CallBarState.IN_CALL) {
        setDialpadVisible(false);
        setCallState(NavigationDiallerCallState.ringing);
      } else if (callBarStateData === CallBarState.IN_PROGRESS) {
        // The timer starts running after the Call has been answered.
        const newIntervalId = setInterval(handleOnCallTimerInterval, 1000);
        setIntervalId(newIntervalId);
        setDialpadVisible(false);
        setCallState(NavigationDiallerCallState.answered);
      } else if (callBarStateData === CallBarState.POST_CALL) {
        setDialpadVisible(false);
        setCallState(NavigationDiallerCallState.ended);
      } else if (callBarStateData === CallBarState.IDLE) {
        setCallState(NavigationDiallerCallState.dialing);
        setDialpadVisible(true);
        setBackSpaceVisible(true);
        setCallTimeInSeconds(0);
      }

      prevPropsRef.current = callBarStateData;
    }
  }, [callBarStateData, handleOnCallTimerInterval]);

  const handleOptionsReset = useCallback(() => {
    setSelectedOptionType(null);
    setSelectedOption(null);
  }, []);

  const resolveFetchedData = useCallback(() => {
    const availableUsersAvailable = !!availableUsers?.length;
    const unavailableUsersAvailable = includeUnavailableUsers && !!unavailableUsers?.length;
    const availableTeamsAvailable = !hideTeams && availableTeams?.length;
    const data: any = [];

    if (availableUsersAvailable) {
      availableUsers?.forEach((user: any) => {
        let contactStatus = user?.isAvailable ? ContactPersonStatus.Available : ContactPersonStatus.Busy;
        contactStatus = user?.isAvailableMobile ? ContactPersonStatus.AvailableMobile : contactStatus;
        const active = user.isAvailable;
        data.push({
          ...user,
          avatarUrl: user?.photoDetails?.photoUrl,
          avatarFallbackLabel: user?.name,
          contactName: user?.name,
          contactStatus,
          selected: user?.id === selectedOption?.id,
          active,
          optionType: OptionType.user,
        });
      });
    }

    if (unavailableUsersAvailable) {
      unavailableUsers?.forEach((user: any) => {
        data.push({
          ...user,
          avatarUrl: user?.photoDetails?.photoUrl,
          avatarFallbackLabel: user?.name,
          contactName: user?.name,
          contactStatus: ContactPersonStatus.Away,
          selected: user?.id === selectedOption?.id,
          active: false,
          optionType: OptionType.user,
        });
      });
    }

    if (availableTeamsAvailable) {
      availableTeams?.forEach((team: any) => {
        const usersAvailable = team.availableUsers > 0;
        const usersBusy = team.onlineUsers > 0;

        let contactStatus = null;
        let active = false;

        if (usersAvailable) {
          contactStatus = ContactPersonStatus.Available;
          active = true;
        } else if (usersBusy) {
          contactStatus = ContactPersonStatus.Busy;
          active = false;
        } else {
          contactStatus = ContactPersonStatus.Away;
          active = false;
        }

        data.push({
          ...team,
          avatarUrl: null,
          avatarFallbackLabel: team?.name,
          contactName: team?.name,
          contactStatus,
          selected: team?.id === selectedOption?.id,
          active,
          optionType: OptionType.team,
        });
      });
    }

    setAllData(data);
  }, [availableTeams, availableUsers, unavailableUsers, hideTeams, includeUnavailableUsers, selectedOption?.id]);

  // API call to fetch the call forward options
  const fetchCallForwardOptions =  useCallback(async (value: string) => {
    handleOptionsReset();
    setOptionsLoading(false);

    try {
      const includeTeams = !hideTeams;
      const includeBusyUsers = true;
      const includeBusyTeams = true;
      const includeUnavailableTeams = false;
      const {
        availableTeams,
        availableUsers,
        unavailableExternalUsers: unavailableUsers,
      } = await CallAgentSearchService.getCallAgentOptions(
        value,
        includeTeams,
        includeUnavailableUsers,
        includeBusyUsers,
        includeBusyTeams,
        includeUnavailableTeams,
      );
      setAvailableUsers(availableUsers);
      setAvailableTeams(availableTeams);
      setUnavailableUsers(unavailableUsers);
      setOptionsLoading(false);
      resolveFetchedData();
    } catch (error) {
      setAvailableUsers([]);
      setAvailableTeams([]);
      setOptionsLoading(false);
    }
  }, [hideTeams, includeUnavailableUsers, handleOptionsReset, resolveFetchedData]);

  useEffect(() => {
    resolveSearchResultsReceived();
    resolveCallState();
    resolveFetchedData();
  }, [resolveCallState, resolveSearchResultsReceived, resolveFetchedData]);

  useEffect(() => {
    return () => {
      dispatch(cleanupContactSearchByPhone())
      setSelectedContact(null);
      onNavigationDiallerUnmount?.();
    };
  },[]);

  const resolveDiallingFromContactNumbers = () => {
    const authUser = authData?.user;
    const selectedTeam = authData?.userTeam;
    const phoneNumberId = authUser?.preferences?.outgoingCallIdPreference?.phoneNumberId || null;
    const menuItems = [];

    // Organization Phone number
    if (settingsData?.tenantSettings?.phoneNumber?.number) {
      menuItems.push({
        value: settingsData?.tenantSettings?.phoneNumber?.number,
        label: renderMenuItemLabel(settingsData?.tenantSettings?.phoneNumber?.number, faPhoneOffice, 'Organization Number'),
        selected: settingsData?.tenantSettings?.phoneNumber?.id === phoneNumberId || !phoneNumberId,
        disabled: false,
        outgoingPreference: { callerIdType: CallConstants.OutgoingCallIdEnums.Organisation, phoneNumberId: settingsData?.tenantSettings?.phoneNumber?.id },
      });
    }

    // Team Phone number
    if (selectedTeam?.number?.number) {
      menuItems.push({
        value: selectedTeam?.number?.number,
        label: renderMenuItemLabel(selectedTeam?.number?.number, faUsers, selectedTeam?.name),
        selected: selectedTeam?.number?.id === phoneNumberId,
        disabled: false,
        outgoingPreference: { callerIdType: CallConstants.OutgoingCallIdEnums.Team, phoneNumberId: selectedTeam?.number?.id },
      });
    }

    // User phone number and exclue RocketCell Number from the options
    if (authData?.user?.phone?.number && authData?.user?.phone?.allocationType !== 'RC') {
      menuItems.push({
        value: authData?.user?.phone?.number,
        label: renderMenuItemLabel(authData?.user?.phone?.number, faMobile, 'Phone'),
        selected: authUser?.phone?.id === phoneNumberId,
        disabled: false,
        outgoingPreference: { callerIdType: CallConstants.OutgoingCallIdEnums.Personal, phoneNumberId: authData?.user?.phone?.id },
      });
    }

    // User mobile phone number and exclue RocketCell Number from the options
    if (authData?.user?.mobilePhone?.number && authData?.user?.mobilePhone?.allocationType !== 'RC') {
      menuItems.push({
        value: authData?.user?.mobilePhone?.number,
        label: renderMenuItemLabel(authData?.user?.mobilePhone?.number, faMobile, 'Mobile Phone'),
        selected: authUser?.mobilePhone?.id === phoneNumberId,
        disabled: false,
        outgoingPreference: { callerIdType: CallConstants.OutgoingCallIdEnums.Personal, phoneNumberId: authData?.user?.mobilePhone?.id },
      });
    }

    // Local Presence Number
    // if (localPresencePermissionAvailable) {
    //   menuItems.push({
    //     value: CallConstants.OutgoingCallIdEnums.LocalPresence,
    //     label: renderMenuItemLabel('Local presence', faMapMarkerAlt),
    //     selected: (phoneNumberId === null && callerIdType === CallConstants.OutgoingCallIdEnums.LocalPresence) || false,
    //     disabled: false,
    //   });
    // }

    return menuItems;
  };

  const handleCallButtonClicked = () => {
    // const { callEventId, campaignId, history } = this.props;
    const callEventId = null;
    const numberToDial = PhoneNumberService.getNumber(number);

    // we get remoteId for task onClickToDial, So for unsaved contacts there won't be firstName in the selectedContact.
    if (selectedContact && selectedContact?.contactType === 'Task' && !selectedContact?.firstName) {
      OutboundCallService.initiateUnknownContactOutboundCall(authData, settingsData, numberToDial?.number, dialFromNumber);
    } else if (selectedContact) {
      OutboundCallService.initiateOutboundCallWithGdpr(
        authData, 
        settingsData,
        selectedContact, 
        numberToDial?.number,
        dialFromNumber, 
        callEventId
      );
    } else {
      OutboundCallService.initiateUnknownContactOutboundCall(authData, settingsData, numberToDial?.number, dialFromNumber);
    }
  };

  const handleMenuOpen = () => {
    setOutgoingNumberMenuOpen(true);
  }

  const handleMenuClose = () => {
    setOutgoingNumberMenuOpen(false);
  }

  const handleDropDownNumberSelect = (selectedOption: any) => {
    const currentPreference = authData?.user?.preferences?.outgoingCallIdPreference;
    const currentCallerIdType = currentPreference?.callerIdType;
    const currentPhoneNumberId = currentPreference?.phoneNumberId;
    const selectedPhoneNumber = selectedOption?.value;
    const selectedPreference = selectedOption?.outgoingPreference;
    const iscurrentOutgoingCallIdPreference = selectedPreference?.callerIdType === currentCallerIdType && selectedPreference?.phoneNumberId === currentPhoneNumberId;

    setDialFromNumber(selectedPhoneNumber);

    // This handler function will be called on initial render of the component
    // So we need to make sure not to update on component initial render
    if (outgoingNumberMenuOpen && !iscurrentOutgoingCallIdPreference) {
      dispatch(setUserOutboundCallNumberState(selectedPreference));
    }
  }

  const handleRecordingToggle = () => {
    // const enableRecordingToggleOnOff = this.callRecordingToggleOffWritePermission;

    // if (!enableRecordingToggleOnOff) {
    //   return;
    // }

    if (!recordingConsent) {
      dispatch(setCallRecordingConsentData({callRecordId: currentActiveCallRecordId, consent: true}));
      dispatch(setCallRecordingStatusData({callRecordId: currentActiveCallRecordId, status: true}));
      TwilioVoiceService.startRttStreaming(currentActiveCallRecordId);
    } else {
      TwilioVoiceService.pauseRecording(currentActiveCallRecordId, recordingStatus);
      dispatch(setCallRecordingStatusData({callRecordId: currentActiveCallRecordId, status: !recordingStatus}));
    }
  };

  // const debouncedFetchCallForwardOptions = useCallback((value: string) => debounce(fetchCallForwardOptions(value), 1000), [fetchCallForwardOptions]);
  const debouncedFetchCallForwardOptions = debounce((value: string) => {
      return new Promise((resolve) => {
        fetchCallForwardOptions(value).then(() => {
          resolve(console.log('fetchCallForwardOptions resolved'));
        });
      });
    }, 1000);

  // Call forwarding handlers
  const handleSearchTermChanged = useCallback((event: any) => {
    setSearchTerm(event.target.value);
    debouncedFetchCallForwardOptions(event.target.value);
  }, [debouncedFetchCallForwardOptions]);

  const handleHangUp = useCallback(() => {
    TwilioVoiceService.disconnect();
    setCallState(NavigationDiallerCallState.ended);
    setDialpadVisible(false);
    setBackSpaceVisible(false);
    onCallHangup();
  }, [onCallHangup]);

  const handleNumberOnChange = (event: any) => {
    setNumber(event.target.value);
    if (selectedContact) {
      setSelectedContact(null);
    }
    handleNumberChangeForContactSearch(event.target.value);
  }

  const handleDialPadClicked = useCallback(() => {
    setDialpadVisible(prevState => !prevState);
    setBackSpaceVisible(prevState => !prevState);
  }, []);

  const handleMicMuteToggle = useCallback(() => {
    TwilioVoiceService.muteMic(!isMicMute);
    setIsMicMute(!isMicMute);
  }, [isMicMute]);

  const handleOnCallHold = useCallback(() => {
    const callMetaData = currentCallsOnHoldData?.metadata;
    const callIsPutOnHold = !!(callsOnHoldData?.[currentActiveCallRecordId]);

    if (callIsPutOnHold) {
      setIsMicMute(false);
      dispatch(callResume({ currentActiveCallRecordId, callMetaData: callMetaData || null }));
    } else {
      // Reconnect API Called
      dispatch(callHold({ currentActiveCallRecordId, callMetaData: callMetaData || null }));
    }
  }, [callsOnHoldData, currentActiveCallRecordId, currentCallsOnHoldData?.metadata, dispatch]);

  const handlePopperrState = useCallback(() => {
    setSelectedContact(null);
  }, []);

  const handleNumberChangeForContactSearch = useCallback((phoneNumber: string) => {
    const numberIsValid = PhoneNumberService.isValid(phoneNumber || '');
    dispatch(setMatchingContacts([]));

    if (numberIsValid) {
      const phoneNumberObj = PhoneNumberService.getNumber(phoneNumber);
      SaleforceService.getContactSearchByPhone(phoneNumberObj?.number || phoneNumber)
        .then((result) => {
          dispatch(setMatchingContacts(result || []));
        })
        .catch((error) => {
          console.error(error);
          dispatch(setMatchingContacts([]));
        });
    }
  }, [dispatch]);

  const handleDialpadNumberPressed = useCallback((value: any) => {
    TwilioVoiceService.sendDigits(`${value}`); 
    const processedNumber = number.substring(0, cursorPosition) + value + number.substring(cursorPosition);
    setNumber(processedNumber);
    handleNumberChangeForContactSearch(processedNumber);
    setCursorPosition(cursorPosition + 1);
  }, [number, cursorPosition, handleNumberChangeForContactSearch]);

  const handleBackspaceClicked = useCallback(() => {
    setSelectedContact(null);

    if (cursorPosition > 0) {
      let slicedNumber = number.slice(0, cursorPosition - 1) + number.slice(cursorPosition);
  
      setNumber(slicedNumber || '');
      handleNumberChangeForContactSearch(slicedNumber || '');
      setCursorPosition(cursorPosition - 1);
    }
  }, [number, cursorPosition, handleNumberChangeForContactSearch]);

  const handleCursorPositionChange = (position: any) => {
    setCursorPosition(position);
  };

  // Resolve the call time according to the Call State.
  const resolveCallTime = () => {
    let result = '';

    if (callState === NavigationDiallerCallState.dialing) {
      result = 'Not Connected';
    } else if (callState === NavigationDiallerCallState.connecting) {
      result = 'Connecting...';
    } else if (callState === NavigationDiallerCallState.ringing) {
      result = 'Ringing...';
    } else if (callState === NavigationDiallerCallState.answered) {
      result = CommunicationService.formatCallTime(callTimeInSeconds);
    } else if (callState === NavigationDiallerCallState.ended) {
      clearInterval(intervalId);
      result = CommunicationService.formatCallTime(callTimeInSeconds);
    }
    return result;
  };

  const handleCallForwardCardClose = useCallback(() => {
    handleOptionsReset();
    setShowCallForwardCard(false);
  }, [handleOptionsReset]);

  const handleCallForwardedToUser = useCallback(async (destinationUser: any) => {
    const { callRecordId, callDirection } = callDetails?.metadata || {};

    try {
      setForwardInProgress(true);
      await CallForwardService.forwardCallToUser(callRecordId, callDirection, destinationUser.id);
      setForwardInProgress(false);
      setShowCallForwardCard(false);
    } catch (error) {
      setForwardInProgress(false);
      // Logger.warn(LogTitle, 'Failed to forward the call to user', { error });
    }
  }, [callDetails?.metadata]);

  
  const handleCallForwardedToTeam = useCallback(async (destinationTeam: any) => {
    const { callRecordId, callDirection } = callDetails?.metadata || {};

    try {
      setForwardInProgress(true);
      await CallForwardService.forwardCallToTeam(callRecordId, callDirection, destinationTeam.id);
      setForwardInProgress(false);
      setShowCallForwardCard(false);
    } catch (error) {
      setForwardInProgress(false);
      // Logger.warn(LogTitle, 'Failed to forward the call to team', { error });
    }
  }, [callDetails?.metadata]);

  const handleCallForwardClicked = useCallback(() => {
    if (selectedOptionType === OptionType.user) handleCallForwardedToUser(selectedOption);
    else if (selectedOptionType === OptionType.team) handleCallForwardedToTeam(selectedOption);
    // else Logger.warn(LogTitle, `Unknown option type: ${selectedOptionType}. Ignoring call forward click`);
  }, [selectedOptionType, selectedOption, handleCallForwardedToTeam, handleCallForwardedToUser]);

  const handleOnForwardOptionSelect = useCallback((value: any) => {
    setSelectedOption(value);
    setSelectedOptionType(value.optionType);
  }, []);

  const handleUseDialpadClicked = useCallback(() => {
    setDialpadOpen(true);
    handleOptionsReset();
  }, [handleOptionsReset]);

  
  const handleCallForwardNumberChanged = (event: any) => {
    setDialpadNumber(event.target.value);
  };

  const handleCallForwardNumberPressed = (value: any) => {
    setDialpadNumber(`${dialpadNumber}${value}`)
  };

  const handleCallForwardBackspaceClicked = () => {
    setDialpadNumber(dialpadNumber?.slice(0, -1) || '')
  };

  const handleCallForwardBackButtonClicked = () => {
    handleOptionsReset();
    setDialpadOpen(false);
    setDialpadNumber('')
  };

  const handleCallForwardToExternalNumber = async () => {
    const { callRecordId, callDirection } = callDetails?.metadata || {};

    try {
      setForwardInProgress(true);
      await CallForwardService.forwardCallToExternalNumber(callRecordId, callDirection, dialpadNumber);
      setForwardInProgress(false);
      setShowCallForwardCard(false);
    } catch (error) {
      setForwardInProgress(false);
      // Logger.warn(LogTitle, 'Failed to forward the call to external number', { error });
    }
  };

  useEffect (() => {
    if (selectedSfContact?.mobilePhone || selectedSfContact?.phone || selectedSfContact?.dialNumber ) {
      const dialNumber = selectedSfContact?.dialNumber || selectedSfContact?.mobilePhone || selectedSfContact?.phone;
      setNumber(dialNumber);
      setSelectedContact(selectedSfContact)
      setCursorPosition(dialNumber.length);
      handleNumberChangeForContactSearch(dialNumber);
    }
  }, [selectedSfContact, handleNumberChangeForContactSearch])


  const dropdownNumberItems = resolveDiallingFromContactNumbers();
  const numberIsValid = PhoneNumberService.isValid(number || '');
  const callButtonDisabled = !numberIsValid;
  const showBackSpaceButton = !!number && backSpaceVisible;
  const contactName = selectedContact ? ContactService.getContactFullName(selectedContact) : '';
  const callTime = resolveCallTime();
  const hideNumberPad = callState !== NavigationDiallerCallState.dialing
  const callIsPutOnHold = !!(callsOnHoldData?.[currentActiveCallRecordId]);
  const isCallRecording = recordingStatus || false;
  const proceedButtonDisabled = !selectedOption;
  const dataLoading = optionsLoading || forwardInProgress;
  const dataCount = allData?.length;
  const forwardNumberIsValid = PhoneNumberService.isValid(dialpadNumber || '');
  const dialpadProceedButtonDisabled = !forwardNumberIsValid;

  // we will implement permission later. For now let's putt as FALSE
  // const recordingButtonDisabled = !this.callRecordingToggleOffWritePermission || !this.callRecordingPermission;
  const recordingButtonDisabled = false;

  return (
    <div className={classes.navigationDiallerContainer}>
      <NavigationDialler
        id={`${id}-NavigationDialler`}
        callState={callState}
        callTime={callTime}
        contactNumbers={dropdownNumberItems}
        onCallClicked={handleCallButtonClicked}
        onHangUp={handleHangUp}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        onDropDownSelect={handleDropDownNumberSelect}
        onCallRecord={handleRecordingToggle}
        onAddBookmark={() => console.log('Bookmark clicked')}
        onNumberChanged={handleNumberOnChange}
        onDialpadClicked={handleDialPadClicked}
        onCallMute={handleMicMuteToggle}
        onCallHold={handleOnCallHold}
        isMicMute={isMicMute}
        isOnHold={callIsPutOnHold}
        isCallRecording={isCallRecording}
        title="Call Forwarding"
        proceedButtonLabel="Forward Call"
        proceedButtonDisabled={proceedButtonDisabled}
        showCallForwardCard={showCallForwardCard}
        inputValue={searchTerm}
        onInputChange={handleSearchTermChanged}
        onCallForwardButtonClicked={() => {fetchCallForwardOptions(''); setShowCallForwardCard(true)}}
        onCallForwardCardClose={handleCallForwardCardClose}
        dataLoading={dataLoading}
        onProceed={handleCallForwardClicked}
        onSelect={handleOnForwardOptionSelect}
        data={allData}
        dataTotalCounts={dataCount}
        getId={item => item.id}
        getAvatarUrl={item => item.avatarUrl}
        getAvatarFallbackLabel={item => item.avatarFallbackLabel}
        getContactName={item => item.contactName}
        getContactStatus={item => item.contactStatus}
        isSelected={item => item.selected}
        isActive={item => item.active}
        dialpadOpen={dialpadOpen}
        externalNumber={dialpadNumber}
        onUseDialpadClicked={handleUseDialpadClicked}
        dialpadProceedButtonDisabled={dialpadProceedButtonDisabled}
        onCallForwardNumberChange={handleCallForwardNumberChanged}
        onCallForwardNumberPressed={handleCallForwardNumberPressed}
        onCallForwardBackSpaceClicked={handleCallForwardBackspaceClicked}
        onCallForwardBackButtonClicked={handleCallForwardBackButtonClicked}
        onDialpadProceed={handleCallForwardToExternalNumber}
        onGoToCallCenter={() => console.log('Call center clicked')}
        onGoToWrapUp={() => console.log('Wrapup clicked')}
        onClose={handlePopperrState}
        getMoreNumberValue={() => '+441111111111'}
        getMoreNumberIcon={() => <FontAwesomeIcon style={{ fontSize: '16px' }} color="#FFFFFF" icon={faPhoneOffice as IconProp} />}
        isMoreNumberSelected={item => item.number === "+94711111112"}
        onMoreNumberPanelExpand={(moreNumberOpened) => console.log('More number panel click', moreNumberOpened)}
        onMoreNumberItemClick={number => console.log('Number clicked', number)}
        callButtonDisabled={callButtonDisabled}
        recordingButtonDisabled={recordingButtonDisabled}
        hideNumberPad={hideNumberPad}
        showDialpad={dialpadVisible}
        contactName={contactName}
        contactCount={contactSearchByPhoneDataCount}
        number={number}
        onDialpadNumberPressed={handleDialpadNumberPressed}
        onBackspaceClick={handleBackspaceClicked}
        showBackSpace={showBackSpaceButton}
        onCursorPositionChange={handleCursorPositionChange}
      />
  </div>
  );
});

NavigationDiallerContainerComponent.defaultProps = {
  hideTeams: false,
  includeUnavailableUsers: false,
};

export default NavigationDiallerContainerComponent;
